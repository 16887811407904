import React, {forwardRef} from "react";

import {FieldWrapper, IFieldWrapperProps} from "../../atoms/wrapper/FieldWrapper";
import {getFieldState} from "../../field_state_helper";
import {getErrorMessage} from "../../utils/get_error_message";
import {Textarea} from "./TextArea";
import {ITextareaCoreProps} from "./TextareaCore";

export type ITextareaFieldProps = IFieldWrapperProps &
    ITextareaCoreProps & {
        error?: string | string[];
    };

export const TextareaField = forwardRef<HTMLTextAreaElement, ITextareaFieldProps>((props, textAreaForwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {labelContent, message, id, className, error, ...textInputProps} = props;

    const errorMessage = getErrorMessage(props.error);
    const fieldState = getFieldState(props);

    return (
        <FieldWrapper labelContent={labelContent} fieldState={fieldState} message={errorMessage || message} htmlFor={id} className={className}>
            <Textarea {...textInputProps} ref={textAreaForwardedRef} id={id} fieldState={fieldState} />
        </FieldWrapper>
    );
});
import React, {forwardRef} from "react";

import {FieldWrapper, IFieldWrapperProps} from "../../atoms/wrapper/FieldWrapper";
import {getFieldState} from "../../field_state_helper";
import {getErrorMessage} from "../../utils/get_error_message";
import {Textarea} from "./TextArea";
import {ITextareaCoreProps} from "./TextareaCore";

export type ITextareaFieldProps = IFieldWrapperProps &
    ITextareaCoreProps & {
        error?: string | string[];
    };

export const TextareaField = forwardRef<HTMLTextAreaElement, ITextareaFieldProps>((props, textAreaForwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {labelContent, message, id, className, error, ...textInputProps} = props;

    const errorMessage = getErrorMessage(props.error);
    const fieldState = getFieldState(props);

    return (
        <FieldWrapper labelContent={labelContent} fieldState={fieldState} message={errorMessage || message} htmlFor={id} className={className}>
            <Textarea {...textInputProps} ref={textAreaForwardedRef} id={id} fieldState={fieldState} />
        </FieldWrapper>
    );
});
