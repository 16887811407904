import {FormikProps} from "formik";

export interface IFormFieldProps<TName extends string, TValue> {
    name: TName;
    value: TValue;
    error?: string[];
    onChange: (fieldName: string, value: TValue, shouldValidate?: boolean, file?: File | undefined) => void;
    onAfterChange: (fieldName: string, value: TValue) => void;
    checked: boolean;
}

/*
 * TODO: This function should return specific set of props based on the type of a field.
 * For example for Checkbox we should return base set of props + `checked` field.
 * There is a chance that we can do this by using union type and enum with field types.
 */

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getFormikFieldProps = <T extends Record<string, any>>(props: FormikProps<T>, fieldName: keyof T): IFormFieldProps<string, any> => {
    const fieldProps = props.getFieldProps(fieldName as string);

    return {
        error: (props.touched[fieldName] && (props.errors[fieldName] as string[])) || undefined,
        value: fieldProps.value,
        onChange: props.setFieldValue,
        name: fieldName as string,
        onAfterChange: () => null,
        checked: !!fieldProps.value
    };
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getFormikFileFieldProps = <T extends Record<string, any>>(props: FormikProps<T>, fieldName: keyof T): IFormFieldProps<string, any> => {
    return {
        error: (props.touched[fieldName] && (props.errors[fieldName] as string[])) || undefined,
        value: props.values[fieldName],
        onChange: (fieldName, fieldValue, file) => {
            props.setFieldValue(fieldName, {
                filePath: fieldValue,
                file
            });
        },
        name: fieldName as string,
        onAfterChange: () => null,
        checked: !!props.values[fieldName]
    };
};
import {FormikProps} from "formik";

export interface IFormFieldProps<TName extends string, TValue> {
    name: TName;
    value: TValue;
    error?: string[];
    onChange: (fieldName: string, value: TValue, shouldValidate?: boolean, file?: File | undefined) => void;
    onAfterChange: (fieldName: string, value: TValue) => void;
    checked: boolean;
}

/*
 * TODO: This function should return specific set of props based on the type of a field.
 * For example for Checkbox we should return base set of props + `checked` field.
 * There is a chance that we can do this by using union type and enum with field types.
 */

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getFormikFieldProps = <T extends Record<string, any>>(props: FormikProps<T>, fieldName: keyof T): IFormFieldProps<string, any> => {
    const fieldProps = props.getFieldProps(fieldName as string);

    return {
        error: (props.touched[fieldName] && (props.errors[fieldName] as string[])) || undefined,
        value: fieldProps.value,
        onChange: props.setFieldValue,
        name: fieldName as string,
        onAfterChange: () => null,
        checked: !!fieldProps.value
    };
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getFormikFileFieldProps = <T extends Record<string, any>>(props: FormikProps<T>, fieldName: keyof T): IFormFieldProps<string, any> => {
    return {
        error: (props.touched[fieldName] && (props.errors[fieldName] as string[])) || undefined,
        value: props.values[fieldName],
        onChange: (fieldName, fieldValue, file) => {
            props.setFieldValue(fieldName, {
                filePath: fieldValue,
                file
            });
        },
        name: fieldName as string,
        onAfterChange: () => null,
        checked: !!props.values[fieldName]
    };
};
