import React, {ChangeEvent, forwardRef, useState} from "react";

import {FieldWrapper, IFieldWrapperProps} from "../../../atoms/wrapper/FieldWrapper";
import {getFieldState} from "../../../field_state_helper";
import {getErrorMessage} from "../../../utils/get_error_message";
import {FileInput, IFileInputProps} from "./FileInput";

export type IFileFieldProps = IFieldWrapperProps &
    IFileInputProps & {
        error?: string | string[];
    };

export const FileField = forwardRef<HTMLInputElement, IFileFieldProps>((props, inputForwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {labelContent, message, id, className, onChange, error, ...inputProps} = props;
    const [fileName, setFileName] = useState("");

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.currentTarget.files ? e.currentTarget.files[0] : undefined;
        setFileName(file?.name ?? "");

        onChange?.(e);
    };

    const fieldState = getFieldState(props);
    const errorMessage = getErrorMessage(props.error);

    return (
        <FieldWrapper labelContent={labelContent} fieldState={fieldState} message={errorMessage || message} htmlFor={id} className={className}>
            <FileInput ref={inputForwardedRef} {...inputProps} id={id} fieldState={fieldState} fileName={fileName} type="file" onChange={onChangeHandler} />
        </FieldWrapper>
    );
});
import React, {ChangeEvent, forwardRef, useState} from "react";

import {FieldWrapper, IFieldWrapperProps} from "../../../atoms/wrapper/FieldWrapper";
import {getFieldState} from "../../../field_state_helper";
import {getErrorMessage} from "../../../utils/get_error_message";
import {FileInput, IFileInputProps} from "./FileInput";

export type IFileFieldProps = IFieldWrapperProps &
    IFileInputProps & {
        error?: string | string[];
    };

export const FileField = forwardRef<HTMLInputElement, IFileFieldProps>((props, inputForwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {labelContent, message, id, className, onChange, error, ...inputProps} = props;
    const [fileName, setFileName] = useState("");

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.currentTarget.files ? e.currentTarget.files[0] : undefined;
        setFileName(file?.name ?? "");

        onChange?.(e);
    };

    const fieldState = getFieldState(props);
    const errorMessage = getErrorMessage(props.error);

    return (
        <FieldWrapper labelContent={labelContent} fieldState={fieldState} message={errorMessage || message} htmlFor={id} className={className}>
            <FileInput ref={inputForwardedRef} {...inputProps} id={id} fieldState={fieldState} fileName={fileName} type="file" onChange={onChangeHandler} />
        </FieldWrapper>
    );
});
