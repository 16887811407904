import React, {FormEvent} from "react";

import {IInputFieldProps, InputField} from "@pg-design/inputs-module";

export type IInputProps = Omit<IInputFieldProps, "onChange"> & {
    onChange: (name: string, value: string) => void;
    onAfterChange?: (name: string, value: string) => void;
};

export const Input = (props: IInputProps) => {
    // destructuring mostly to remove some props and spread rest into input component.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {onChange, onAfterChange, ...restProps} = props;

    const onChangeHandler = (e: FormEvent<HTMLInputElement>) => {
        onChange?.(e.currentTarget.name, e.currentTarget.value);
    };

    return <InputField {...restProps} onChange={onChangeHandler} className={props.className} />;
};
import React, {FormEvent} from "react";

import {IInputFieldProps, InputField} from "@pg-design/inputs-module";

export type IInputProps = Omit<IInputFieldProps, "onChange"> & {
    onChange: (name: string, value: string) => void;
    onAfterChange?: (name: string, value: string) => void;
};

export const Input = (props: IInputProps) => {
    // destructuring mostly to remove some props and spread rest into input component.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {onChange, onAfterChange, ...restProps} = props;

    const onChangeHandler = (e: FormEvent<HTMLInputElement>) => {
        onChange?.(e.currentTarget.name, e.currentTarget.value);
    };

    return <InputField {...restProps} onChange={onChangeHandler} className={props.className} />;
};
