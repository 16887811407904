import React, {ChangeEvent} from "react";

import {ITextareaFieldProps, TextareaField} from "@pg-design/inputs-module";

export type ITextAreaProps = Omit<ITextareaFieldProps, "onChange"> & {
    onChange: (name: string, value: string) => void;
    onAfterChange?: (name: string, value: string) => void;
};

export const TextArea = (props: ITextAreaProps) => {
    // destructuring mostly to remove some props and spread rest into input component.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {onChange, onAfterChange, ...restProps} = props;

    const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(e.currentTarget.name, e.currentTarget.value);
    };

    return <TextareaField {...restProps} onChange={onChangeHandler} className={props.className} />;
};
import React, {ChangeEvent} from "react";

import {ITextareaFieldProps, TextareaField} from "@pg-design/inputs-module";

export type ITextAreaProps = Omit<ITextareaFieldProps, "onChange"> & {
    onChange: (name: string, value: string) => void;
    onAfterChange?: (name: string, value: string) => void;
};

export const TextArea = (props: ITextAreaProps) => {
    // destructuring mostly to remove some props and spread rest into input component.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {onChange, onAfterChange, ...restProps} = props;

    const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(e.currentTarget.name, e.currentTarget.value);
    };

    return <TextareaField {...restProps} onChange={onChangeHandler} className={props.className} />;
};
