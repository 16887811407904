import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CheckboxCheckedIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.75 1.25h-7.5v7.5h7.5v-7.5ZM4.167 7.083 2.083 5l.588-.588 1.496 1.492 3.162-3.162.588.591-3.75 3.75Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CheckboxCheckedIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.75 1.25h-7.5v7.5h7.5v-7.5ZM4.167 7.083 2.083 5l.588-.588 1.496 1.492 3.162-3.162.588.591-3.75 3.75Z" />
        </SvgIcon>
    );
};
