export type CheckboxSize = "md" | "lg";

export const checkboxSizeValue = {
    md: "2.4rem",
    lg: "3.2rem"
};
export type CheckboxSize = "md" | "lg";

export const checkboxSizeValue = {
    md: "2.4rem",
    lg: "3.2rem"
};
