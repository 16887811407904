import React, {forwardRef, useRef} from "react";

import {useCombinedRefs} from "@pg-mono/hooks";

import {TextInputBase} from "../../atoms/TextInputBase";
import {FieldWrapper} from "../../atoms/wrapper/FieldWrapper";
import {IInputWrapperProps, InputWrapper} from "../../atoms/wrapper/InputWrapper";
import {getFieldState} from "../../field_state_helper";
import {IFieldState} from "../../types";
import {ITextareaCoreProps, TextareaCore} from "./TextareaCore";

export type ITextAreaProps = IInputWrapperProps & ITextareaCoreProps & {message?: string; error?: string[] | undefined};
type ITextAreaOwnProps = ITextAreaProps;

export const Textarea = forwardRef<HTMLTextAreaElement, ITextAreaOwnProps>((props, forwardedRef) => {
    const {fieldState: _fieldState, detail, className, message, ...restProps} = props;
    const localRef = useRef<HTMLTextAreaElement | null>(null);
    const inputRef = useCombinedRefs<HTMLTextAreaElement>(localRef, forwardedRef);

    const events = {
        onClick: (event: React.MouseEvent) => {
            event.stopPropagation();

            if (localRef.current) {
                localRef.current.focus();
            }
        }
    };

    const innerFieldState: IFieldState = getFieldState(props);

    return (
        <FieldWrapper fieldState={innerFieldState} message={props.error?.join(", ") || message}>
            <TextInputBase onClick={events.onClick} fieldState={innerFieldState} className={className}>
                <InputWrapper fieldState={innerFieldState} detail={detail}>
                    <TextareaCore rows={4} {...restProps} ref={inputRef} />
                </InputWrapper>
            </TextInputBase>
        </FieldWrapper>
    );
});
import React, {forwardRef, useRef} from "react";

import {useCombinedRefs} from "@pg-mono/hooks";

import {TextInputBase} from "../../atoms/TextInputBase";
import {FieldWrapper} from "../../atoms/wrapper/FieldWrapper";
import {IInputWrapperProps, InputWrapper} from "../../atoms/wrapper/InputWrapper";
import {getFieldState} from "../../field_state_helper";
import {IFieldState} from "../../types";
import {ITextareaCoreProps, TextareaCore} from "./TextareaCore";

export type ITextAreaProps = IInputWrapperProps & ITextareaCoreProps & {message?: string; error?: string[] | undefined};
type ITextAreaOwnProps = ITextAreaProps;

export const Textarea = forwardRef<HTMLTextAreaElement, ITextAreaOwnProps>((props, forwardedRef) => {
    const {fieldState: _fieldState, detail, className, message, ...restProps} = props;
    const localRef = useRef<HTMLTextAreaElement | null>(null);
    const inputRef = useCombinedRefs<HTMLTextAreaElement>(localRef, forwardedRef);

    const events = {
        onClick: (event: React.MouseEvent) => {
            event.stopPropagation();

            if (localRef.current) {
                localRef.current.focus();
            }
        }
    };

    const innerFieldState: IFieldState = getFieldState(props);

    return (
        <FieldWrapper fieldState={innerFieldState} message={props.error?.join(", ") || message}>
            <TextInputBase onClick={events.onClick} fieldState={innerFieldState} className={className}>
                <InputWrapper fieldState={innerFieldState} detail={detail}>
                    <TextareaCore rows={4} {...restProps} ref={inputRef} />
                </InputWrapper>
            </TextInputBase>
        </FieldWrapper>
    );
});
